import React from 'react';
import useScript from './../hooks/useScript';
import { LBL_GET_ACCESS_TEXT, LBL_FOR } from './../../shared/constants';

/*
     useEffect(() => { 
    });
*/

const DroppButton = (props) => {
    const { perDayFee, merchantId, siteUrl, reference, onButtonClick, buttonText, deviceType } = props;
    useScript(process.env.REACT_APP_DROPP_MERCHANT_PORTAL);
    const dt = new Date();

    return (   
        <div style={{}} className={`mb-2`}>
            <button className="dropp-payment p-button"
                title= {`${LBL_GET_ACCESS_TEXT } ${LBL_FOR} ${perDayFee}`}
                onClick={onButtonClick}
                data-amount={perDayFee}
                data-callback-url={`${siteUrl}/callback/_dropp`}
                data-currency="USD"
                data-description={`${dt.toLocaleString()} - ${new Date(dt.getTime() + 24 * 60 * 60 * 1000).toLocaleString()}`}
                data-merchant-id={`${merchantId}`}
                data-reference={`${reference}_${deviceType}`}
                data-thumbnail={`https://hashpoint-dev.netlify.app/h-logo-white.png`}
                data-title={`24 hour ${deviceType} session`}
                data-type="session"
                style={{padding: '.7rem 1rem'}}
            >
                <div>
                    {`${buttonText ? buttonText : LBL_GET_ACCESS_TEXT} `} ${perDayFee}&nbsp;
                    <img alt="dropp icon"
                        style={{height: '1rem'}}
                        src="https://merchant.portal.dropp.cc/images/dropp_icon.png"/>
                </div>
            </button>
        </div>        
    );
  }

  export default DroppButton