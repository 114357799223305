import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from "react-router-dom";
//import loadable from '@loadable/component';
import { Toast } from 'primereact/toast';
//import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import DroppButton from './../components/buttons/dropp';
import Logo from '../components/logo-h';
import Terms from './../components/boilerplate/terms';
//import { } from './../shared/options';
import { delay, nanoToUtcDate, nowToNano } from './../shared/fn';
import { APP_NAME_0
        , APP_NAME_1
        , LBL_TERMS_CONDITIONS
        , LBL_GET_ACCESS_TEXT
        , LBL_PAYMENT_TAG_LINE
        , LBL_SKIP_STEP
        , LBL_USE_OF_SITE
} from './../shared/constants';
import './_header.scss';

//const Countdown = loadable(() => import('./countdown'));

const Header = ({ sessionState, appState, handleSessionExpire, deviceType }) => {    
    const toast = useRef(null);
    const [isDashboard, setIsDashboard] = useState(false);
    const location = useLocation();
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [lsHideWalletWarning, setLsHideWalletWarning] = useState(false);
    const [merchantId] = useState(process.env.REACT_APP_DROPP_MERCHANT_ID);
    const [showTerms, setShowTerms] = useState(false);
    const [showDroppDialog, setShowDroppDialog] = useState(false);
    //const [xxx, setXxx] = useState(false);

    let token=sessionState?.userData.sessionId;
    const dateExpires=sessionState?.userData.dateExpires;
    const fee = appState?.appSettings?.find(x => x.settingname==='per_day_fee')?.settingvalue;
   
    const session_ref = JSON.parse(localStorage.getItem('session_ref'));
    
    /* if (sessionState === undefined) {
        return <div></div>; // Replace this with a spinner or your loading component
    } */

    if(session_ref && session_ref?.dateExpires - nowToNano() > 0) {
        token = session_ref?.sessionId;
    }

    useEffect(() => {
        setIsDashboard(location.pathname.toLowerCase() === '/dashboard');

        const hideWarning = localStorage.getItem('__sak:hide-wallet-warning')
        setLsHideWalletWarning(hideWarning);

        // const setMaintModeVis = () => {
        //     const isDev=localStorage.getItem('23aa42f6-86bd-438c-96c0-d7d15404eb72');
        //     const mMode = appState?.appSettings?.find(x => x.settingname==='maint_mode')?.settingvalue==='1';
        //     setMaintMode(mMode);

        //     const asm = document.getElementById('AppStateMessage');
        //     if(isDev) {
        //         setIsDevUser(true);                
        //     }

        //     if(mMode && !isDev) {
        //         appActions.setMessage('We are currently performing system upgrades and will be back online shortly.');
        //         asm?.classList.remove('hidden');
        //     }
        //     else {
        //         asm?.classList.add('hidden');
        //     }
        // }

        const setVis = async() => {
            await delay(2000);            
            const btn = document.getElementById('GetAccess');

            if(btn) {
                if(!token) {
                    btn?.classList.remove('hidden');
                }
                else {                    
                    btn?.classList.add('hidden');
                }
            }
        }

        //setMaintModeVis();
        setVis();

     }, [location.pathname, token, appState]);  //, maintMode, isDevUser

    const onDontShowAgainClick =(checked)=> {
        setDontShowAgain(checked)
    }

    const onShowTermsClick = () => {
        setShowTerms(true);
    }

    const closeTermsDialog = () => {
        setShowTerms(false);
    }

    const onDroppButtonClick = async() => {
        localStorage.setItem('__sak:hide-wallet-warning', dontShowAgain);
    }

    const openDroppDialog = async() => {
        setShowDroppDialog(true);
    }

    const closeDroppDialog = () => {
        setShowDroppDialog(false);
    }

    const rightContents = (
        <div style={{width: '100%'}}>
            {/* {token &&  (
                <Countdown 
                    token={token} 
                    expireDt={nanoToUtcDate(dateExpires)}
                    handleSessionExpire={ handleSessionExpire } 
                    deviceType={deviceType}
                />                
            )}

            {(!token) && (
            <>
                <Button 
                    type="button" 
                    id='GetAccess'
                    name='Get Access'
                    icon="pi pi-unlock" 
                    label={`${LBL_GET_ACCESS_TEXT}`}
                    onClick={(e) => openDroppDialog()} 
                    aria-haspopup aria-controls="overlay_panel" 
                    className={`p-button-outlined p-button login hidden`}
                />
            </>
            )} */}
        </div>
    );    

    const centerContents = (
        <div className={`${isDashboard ? 'flex' : 'hidden'}`} style={{width: '100%', border: '0px solid red', justifyContent: 'right'}}>
            <img src='./powered-by-hgraph.png' alt='powered by hgraph' style={{width: '30%', }} />
        </div>
    );

    const leftContents = (
        <div style={{width: '100%'}}>
            <Link to="" >
                <div className={`app-name flex ${deviceType}`} style={{paddingBottom: deviceType === 'desktop' ? '' : '.5rem'}}>
                    <div><Logo /></div>
                    <div style={{paddingLeft: '1rem'}} className={`app-name-0`}>{APP_NAME_0} {APP_NAME_1}</div>
                </div>
            </Link>
        </div>
    );

    const dialogHeader = () => {
        return (
            <div>
                <label>{LBL_GET_ACCESS_TEXT}&nbsp;&nbsp;</label>
            </div>
        );
    }

    return (
        <>
            <header className={`anon`}>
                <Toast ref={ toast } position="bottom-right" />
                <div style={{border: '0px solid blue' , width:'100%', justifyContent: 'space-between', alignItems: 'center'}} className={`flex flex-wrap`}>
                    <div className={`flex`} style={{width: `${deviceType==='desktop' ? 'auto' : '33%'}`, justifyContent: 'left'}}>{ leftContents }</div>
                    <div className={`${deviceType==='desktop' ? '' : 'hidden'}`} style={{width: '33%', justifyContent: ''}}>{ centerContents }</div>
                    <div className={`flex`} style={{width: `${deviceType==='desktop' ? 'auto' : '33%'}`, justifyContent: 'right'}}>{ rightContents }</div>
                </div>
            </header>

            <Dialog 
                header={dialogHeader}
                visible={showDroppDialog}
                className={`dropp-dlg`}
                modal
                position={`top-right`}
                resizable={false}
                draggable={false}
                closeOnEscape={false}
                closable={true}
                blockScroll={true}
                maskClassName={``}
                onHide={closeDroppDialog}
                style={{width: '30%', top:'3.5rem'}}
            >
                <div>
                    <div className={`mb-2`} style={{width: '100%', textAlign: 'center', fontSize: '1.25rem', display: 'none'}}>
                        {`${LBL_PAYMENT_TAG_LINE} $${fee}!`}
                    </div>
                    
                    <div className={`flex justify-content-center`} style={{textAlign: 'center'}}>
                        <DroppButton 
                            perDayFee={fee} 
                            merchantId={merchantId} 
                            siteUrl={process.env.REACT_APP_SITE_URL}
                            reference={process.env.REACT_APP_DROPP_INVOICE_REF}
                            onButtonClick={onDroppButtonClick}
                            skipStep={false}
                            deviceType={deviceType}
                        />
                    </div>

                    <div className={`mt-2 mb-2`} style={{width: '100%', textAlign: 'center'}}>
                        { LBL_USE_OF_SITE }&nbsp;<Link onClick={onShowTermsClick} className={`link`}>{ LBL_TERMS_CONDITIONS }</Link>
                    </div>
                    
                    <div className={`${lsHideWalletWarning===true? 'hidden' : '' } mb-2 p-3`} style={{width: '100%', textAlign: 'center', border: '0px solid #555', borderRadius: '.375rem'}}>
                        <div className={`important_ mb-1 flex`} style={{width: '100%', justifyContent: 'center', fontWeight: '300', lineHeight: '1.25rem'}}>
                            <div style={{width: '100%', }}>
                                First time access requires the Dropp browser extension to be synced with your Dropp mobile wallet.
                                See the <Link to="https://dropp.cc/resources/quick-start-guide/"
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className='italic'
                                style={{fontSize: '1rem'}}>Dropp Quick Start Guide</Link> for help.
                            </div>
                           
                        </div>
                        <Checkbox id='SkipThisStep' onChange={e => onDontShowAgainClick(e.checked)} checked={dontShowAgain} className={'hidden'}/>
                        <label htmlFor="SkipThisStep" className="p-checkbox-label hidden">&nbsp;&nbsp;{LBL_SKIP_STEP}</label>
                    </div>
                </div>
            </Dialog>

            <Dialog 
                header={ LBL_TERMS_CONDITIONS }
                visible={showTerms} 
                style={{ width: '60vw' }} 
                onHide={ closeTermsDialog }
            >
                <div><Terms /></div>
            </Dialog>
        </>
    );
};

export default Header;