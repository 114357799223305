// ZoomContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const ZoomContext = createContext();

export function ZoomProvider({ children }) {
  const [zoomLevel, setZoomLevel] = useState(100);

  useEffect(() => {
    const detectZoom = () => {
      const zoom = Math.round((window.devicePixelRatio * 100));
      setZoomLevel(zoom);
    };

    // Initial detection
    detectZoom();

    // Listen for zoom changes
    window.addEventListener('resize', detectZoom);

    return () => {
      window.removeEventListener('resize', detectZoom);
    };
  }, []);

  return (
    <ZoomContext.Provider value={{ zoomLevel }}>
      {children}
    </ZoomContext.Provider>
  );
}

// Custom hook to use zoom level
export function useZoom() {
  const context = useContext(ZoomContext);
  if (context === undefined) {
    throw new Error('useZoom must be used within a ZoomProvider');
  }
  return context;
}

// Optional: Helper function to calculate scaled dimensions
export function useScaledDimensions(baseWidth, baseHeight) {
  const { zoomLevel } = useZoom();
  const scale = zoomLevel / 100;
  
  return {
    width: Math.round(baseWidth / scale),
    height: Math.round(baseHeight / scale)
  };
}