import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
//import ReactDOM from 'react-dom/client';
import { 
        RouterProvider
        , createBrowserRouter
        , createRoutesFromElements
        , Route
        , Outlet
        , useLocation
        , useSearchParams
        , useNavigate 
    } from 'react-router-dom';

import loadable from '@loadable/component';
import { useQueries } from '@tanstack/react-query';
import { useSessionStore } from './components/stores/useSessionStore';
import { useAppStore } from './components/stores/useAppStore';
import { ZoomProvider, useZoom } from './components/context/zoom';
import useInterceptor from './components/hooks/useInterceptor';
import ErrorBoundary from './components/boundaries/ErrorBoundary';
import { preloadImages, delay, uuidv4, emptyGuid, nowToNano, NANO_DIV } from './shared/fn';
import { isMobile } from 'react-device-detect';
import { Splitter, SplitterPanel } from 'primereact/splitter'; 
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Header from './layout/header';
import Menu from "./layout/menu";
import InfoDialog from './components/dialogs/info';
import WarningDialog from './components/dialogs/warning';
import AddWallet from './components/forms/add-wallet';

import {
    LBL_PAYMENT_FAILURE_TEXT
    , LBL_COOKIES_TEXT
    , LBL_SOMETHING_WRONG_TEXT
    , LBL_NEW_VERSION_PUBLISHED_TEXT    
    , LBL_NEW_VERSION_PUBLISHED
    , LBL_COOKIE_CONSENT
    , NAV_ACCOUNT
    , NAV_GOVCOUNCIL
    , NAV_MODEL
    , NAV_SUPPORT
    , NAV_ROADMAP
} from './shared/constants';

import { isPromise, post, appStoreMessage }from './shared/fn';
import * as api from './api/api';
import './styles/scss/app.scss';
import apiService from './api/apiService';

const Dashboard = loadable(() => import('./routes/dashboard/dashboard'));
const Metrics = loadable(() => import('./routes/metrics/metrics'));
const Default = loadable(() => import('./routes/default/default'));
const NotFound = loadable(() => import('./notfound'));
const Callback = loadable(() => import('./callback/_dropp'));
const Revenue = loadable(() => import('./routes/charts/components/revenue'));
const NetworkData = loadable(() => import('./routes/charts/components/network'));
const StakingEconomics = loadable(() => import('./routes/charts/components/staking'));
const Defi = loadable(() => import('./routes/charts/components/defi'));
const HbarDist = loadable(() => import('./routes/charts/components/distribution'));
const Analytics = loadable(() => import('./routes/analytics/analytics'));
const Roadmap = loadable(() => import('./routes/roadmap/roadmap'));
const Blog = loadable(() => import('./routes/blog/blog'));
const Support = loadable(() => import('./routes/support/support'));
const GC = loadable(() => import('./routes/gc/gc'));
//const Services = loadable(() => import('./routes/account/services/services'));
const Unsubscribe = loadable(() => import('./unsubscribe'));
const Terms = loadable(() => import('./../src/components/boilerplate/terms'));
const Privacy = loadable(() => import('./../src/components/boilerplate/privacy'));
const ProtectedRoute = loadable(() => import('./../src/components/routes/protected'));
const MaintRoute = loadable(() => import('./../src/components/routes/maint'));
const Contact = loadable(() => import('./routes/company/contact'));
const Careers = loadable(() => import('./routes/company/careers'));
const Renew = loadable(() => import('./renew'));

//const queryString = window.location.search;
//const urlParams = new URLSearchParams(queryString);

// const getSessionData = async(args) => {
//     const t=args.queryKey[1];
//     const u=args.queryKey[2];

//     const data=await api.getUserSession(t, u);

//     let sessionId
//     let token
//     let dateExpires;
//     let userId;

//     if(data.data) {
//         sessionId=data.data?.response[0].sessionid;
//         userId=data.data?.response[0].userid;
//         dateExpires=data.data?.response[0].dateexpires;
//         token=data.data?.response[0].accesstoken;
//     }
        
//     return {sessionId, token, dateExpires, userId};
// }

const getAppSettings = async() => {
    const data=await api.getAppSettings();
    return data;
}

const clearHistory = () => {
    window.history.replaceState({}, '');
    window.history.replaceState(null, '', window.location.pathname);
}

const setUserSettings = (val) => {
    
    if(val === "0") return;

    const theRoot = document.getElementById("root");
    theRoot.addEventListener("contextmenu", function(event) {
        event.preventDefault();
    });
}

const AppLayout = () => {
    const [sessionState, actions] = useSessionStore();
    const [appState, { setAppSettings, setMessage }] = useAppStore();
    const [callbackError, setCallbackError] = useState(null);
    const [showResponseCodeDlg, setShowResponseCodeDlg] = useState(false);
    const [showCookieDlg, setShowCookieDlg] = useState(false);
    const [showRefreshDlg, setShowRefreshDlg] = useState(false);
    const [showWalletDlg, setShowWalletDlg] = useState(false);
    //const [maintMode, setMaintMode] = useState(null);
    //const [xxx, setXxx] = useState(null);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const interceptor = useInterceptor();
    const [deviceType] = useState(isMobile ? 'mobile' : 'desktop');
    const isAppRoot = location.pathname === '/';
    const pathName = location.pathname.replace('/','');
    const refreshRef = useRef(false);
    const setRef = useRef(false);
    //const cookieSetRef = useRef(false);
    
    let paymentRef = searchParams.get("ref");
    let userId = searchParams.get("userid");
    let code = searchParams.get("code");
    let message = searchParams.get("message");
    let cid = searchParams.get("cid");

    //const { zoomLevel } = useZoom();

    const [appSettingsResponse] = useQueries({          //userDataResponse
        queries: [
            {
                queryKey: ['get-app-settings-data'],
                queryFn: getAppSettings,
                staleTime: 86400000,
                refetchInterval:86400000,
                enabled: true,
            },
        ],
    });

    const cookieAck = () => {
        const ack = localStorage.getItem('__sak:0r4kcTsUHY');
    
        if(!ack) {
            setShowCookieDlg(true);
        }
    }
    
    const onCookieAckClick = () => {
        setShowCookieDlg(false);
        localStorage.setItem('__sak:0r4kcTsUHY', new Date().getTime());
    }

    const cookieAckDlgFooter = () => {
        return (
            <div>
                <Button label="Accept" icon="pi pi-check" onClick={() => onCookieAckClick()} autoFocus />
            </div>
        );
    }

    const expireSession = useCallback(async() => {
        localStorage.removeItem('session_ref');
    
        // Clear session state
        await actions.setUserData({});
        await actions.setTimeRemaining(null);
    }, [actions]);
    
    const handleSessionExpire = useCallback(async(timeLeft) => {
        await expireSession();
        
        if(timeLeft===0) {
            navigate("renew", {state: {replace: true, timeLeft: timeLeft }});
        }
    }, [expireSession, navigate]);

    const onRefreshAckClick =()=> {
        setShowRefreshDlg(false);
        localStorage.setItem('__sak:MVjuSavEw0', false);
    }

    const refreshAckDlgFooter = (name) => {
        return (
            <div>
                <Button label="OK" icon="pi pi-check" onClick={() => onRefreshAckClick()} autoFocus />
            </div>
        );
    }

    const onInvalidCloseClick =()=> {
        setShowResponseCodeDlg(false);
    }

    const handleWalletDialogClose = (e) => {
        setShowWalletDlg(false);
    }

    const invalidDlgFooter = (name) => {
        return (
            <div>
                <Button label="Close" icon="pi pi-check" onClick={() => onInvalidCloseClick()} autoFocus />
            </div>
        );
    }

    const trackEvent = useCallback(async()=> {
        if(process.env.NODE_ENV ==='development') return;

        await delay(1000);
        
        if(!sessionState?.userData?.token) {
            const emptyUuid = emptyGuid();
            api.trackEvent(pathName, sessionState?.userData.token, emptyUuid);
        }
        else {
            const trackUuid = localStorage.getItem('__sak:de5f44x2x') ?? uuidv4();
            localStorage.setItem('__sak:de5f44x2x', trackUuid);
            api.trackEvent(pathName, sessionState?.userData?.token, trackUuid);
        }
    }, [pathName, sessionState?.userData.token]);

    const setSessionCookie = useCallback(async(data, remaining) => {
        const cookie = JSON.stringify(data)
        await api.setCookie(process.env.REACT_APP_TOKEN, `${cookie}`, remaining);
        return true;
    }, []);

    const setUserSession = useCallback(async(userData, setCookie=true) => {
        if(setRef.current) return;        

        try {
            if(userData) {
                const dateExpires = userData.dateExpires;
                const remaining = parseInt((dateExpires - nowToNano())/NANO_DIV);

                await actions.setUserData(userData);
                await actions.setTimeRemaining(remaining);
                localStorage.setItem('session_ref', JSON.stringify(userData));
                
                if(setCookie && remaining > 0) {
                    await setSessionCookie(userData, remaining);                    
                }
                
                setRef.current = true;
                return;
            }
        }
        catch (ex) {
            console.log('Error:', ex);
        }
    }, [actions, setSessionCookie]);

    const refreshUserSession = useCallback(async() => {
        if(refreshRef.current) return refreshRef.current;
        refreshRef.current = true;

        try {
            //check if they have a session_ref
            const sessionRef = localStorage.getItem('session_ref');
            const cookie = await api.getCookie(interceptor, process.env.REACT_APP_TOKEN);
            const token = cookie.status===200 ? JSON.parse(cookie.data?.cookie) : null;
            
            //track the cookie
            if(process.env.NODE_ENV !== "development") {
                if (sessionRef || token) {
                    const qs = `sessionref=${sessionRef}&cookie=${cookie.data?.cookie?.toString()}`;
                    await post(apiService, 'user_cookie_insert', qs);
                }                
            }

            const ref = token ?? JSON.parse(sessionRef);
            
            if(ref?.token) {    //found a session reference in either a cookie or localStorage, so check if session is still active
                const data = await api.getUserSession(ref.sessionId, ref.userId);

                if(data.data) {
                    const sessionId=data.data?.response[0].sessionid;
                    const userId=data.data?.response[0].userid;
                    const dateExpires=data.data?.response[0].dateexpires;
                    const accessToken=data.data?.response[0].accesstoken;

                    const shouldSetCookie = token === null; //no need to set cookie if session info is coming from the cookie
                    await setUserSession({sessionId, userId, dateExpires, accessToken}, shouldSetCookie);
                    return setRef.current;
                }
                else {
                    await expireSession();
                    return false;
                }                
            }
        }
        catch (ex) {            
            return false;
        }
        
    }, [interceptor, setUserSession, expireSession]);

    useEffect(() => {
        if (appSettingsResponse.isSuccess) {
            if (appSettingsResponse.data.length>0) {
                setAppSettings(appSettingsResponse.data);
                //console.log('appSettingsResponse.data', appSettingsResponse.data)
                const isDisabled=appSettingsResponse.data?.find(x => x.settingname==='disable_right_click')?.settingvalue;
                setUserSettings(isDisabled);
                
                const message = appSettingsResponse.data?.find(x => x.settingname==='app_status_message')?.settingvalue;
                setMessage(message);

                const maint_mode = appSettingsResponse.data?.find(x => x.settingname==='maint_mode')?.settingvalue==='1';
                if(!maint_mode) {
                    preloadImages('./styles/images/landing-bg.png', './styles/images/landing-6.png');
                }
                
            }
        }
    }, [appSettingsResponse, setAppSettings, setMessage]);

    useEffect(() => {
        localStorage.setItem('last_route', pathName);
        
        const setSession = async(userData) => {
            await setUserSession(userData);
        }

        const validateNewSession = async(sessionId, userId) => {
            const data = await api.getUserSession(sessionId, userId);
            
            if(data.data) {
                const sessionId=data.data?.response[0].sessionid;
                const userId=data.data?.response[0].userid;
                const dateExpires=data.data?.response[0].dateexpires;
                const token=data.data?.response[0].accesstoken;

                setSession({sessionId, userId, dateExpires, token});
            }
        }

        const refreshSession = async() => {
            const resp = await refreshUserSession();
            const promise = isPromise(resp);
            if(!promise) return resp;
        }

        trackEvent();
        cookieAck();

        const response = {"data": {}};
        response.data.ref = paymentRef;
        response.data.userId = userId;
        response.data.code = code;
        response.data.message = message !== "undefined" ? message : null;

        clearHistory();

        if(paymentRef && userId && parseInt(code)===0) {
            validateNewSession(paymentRef, userId);
        }
        else if(parseInt(code) < 0 || parseInt(code) > 0) {
            setCallbackError(response);
            setShowResponseCodeDlg(true);
        }
        else if(cid) {
            setShowWalletDlg(true);
        }
        else {
            const resp = refreshSession();
            
            if(resp) {
                navigate(pathName, {state: {replace: true }});
            }
            else {
                navigate("/", {state: {replace: true }});
            }
        }
    }, [
        refreshUserSession
        , setUserSession
        , navigate
        , trackEvent
        , code
        , message
        , paymentRef
        , userId
        , cid
        , pathName
    ]);

    const memoizedSessionState = useMemo(() => sessionState, [sessionState]);
    const memoizedAppState = useMemo(() => appState, [appState]);

    return (
        <main className={`anon ${(process.env.NODE_ENV ==='development') ? 'dev' : ''} ${deviceType}`} data-testid='main-wrapper'>
                <Splitter style={{height: `100vh`, width: `100vw`}} className={`width-${window.innerWidth} main-splitter`}>
                    <SplitterPanel className={`sidebar sidebar-left ${(isAppRoot) ? 'hidden' : ''}`} size={10} minSize={10}>
                        <Menu data-testid='menu'  /> 
                    </SplitterPanel>
                    
                    <SplitterPanel size={90} minSize={90} className={`content`} data-testid='content-panel'>
                        <Splitter layout="vertical">
                            <SplitterPanel size={10} data-testid='header-panel'>
                                <Header 
                                    sessionState={ memoizedSessionState }
                                    appState={ memoizedAppState }
                                    handleSessionExpire={ handleSessionExpire }
                                    deviceType={ deviceType }
                                    data-testid='header'
                                />
                            </SplitterPanel>
                            
                            <SplitterPanel size={90} minSize={90} className={`vertical-container`}>
                                <Splitter>
                                    <SplitterPanel
                                        //className={`${maintMode ? 'hidden' : ''}`}
                                        size={100}
                                        minSize={100} 
                                        style={{}}
                                        data-testid='scroll-panel-wrapper'
                                    >                                    
                                        <ScrollPanel style={{ height: '100vh' }} className={`${isAppRoot ? 'app-root ' : 'route'} `} data-testid='scroll-panel'>
                                            <Outlet data-testid='outlet' />
                                        </ScrollPanel>                                
                                    </SplitterPanel>
                                </Splitter>
                            </SplitterPanel>
                        </Splitter>
                    </SplitterPanel>
                </Splitter>

                <InfoDialog 
                    header={ LBL_COOKIE_CONSENT }
                    footer={ cookieAckDlgFooter('showCookieDlg') }
                    content={ LBL_COOKIES_TEXT }
                    isVisible={ showCookieDlg }
                    clsName={`ack-dlg`}
                    isModal={ true }
                    isResizable={ false }
                    isDraggable={ false }
                    closeOnEscape={ false }
                    isClosable={ false }
                    isBlockScroll={ true }
                    data-testid='cookie-ack-dialog'
                />

                <InfoDialog 
                    header={ LBL_NEW_VERSION_PUBLISHED }
                    footer={ refreshAckDlgFooter }
                    content={ LBL_NEW_VERSION_PUBLISHED_TEXT }
                    isVisible={ showRefreshDlg }
                    clsName={`ack-dlg`}
                    isModal={ true }
                    isResizable={ false }
                    isDraggable={ false }
                    closeOnEscape={ false }
                    isClosable={ false }
                    isBlockScroll={ true }
                    onHide = { onRefreshAckClick }
                    data-testid='new-version-dialog'
                />

                <WarningDialog 
                    header={ LBL_SOMETHING_WRONG_TEXT }
                    footer={ invalidDlgFooter }
                    content= {`${LBL_PAYMENT_FAILURE_TEXT} ${callbackError?.data.code} - ${callbackError?.data?.message}`}
                    isVisible={ showResponseCodeDlg }
                    clsName={`invalid-dlg`}
                    isModal={ true }
                    isResizable={ false }
                    isDraggable={ false }
                    closeOnEscape={ false }
                    isClosable={ false }
                    isBlockScroll={ true }
                    onHide = { onInvalidCloseClick }
                    data-testid='invalid-dialog'
                />

                <Dialog 
                    header="Add Dropp Account Id"
                    visible={showWalletDlg}
                    className={`auth-upd-dlg`}
                    modal
                    resizable={false}
                    draggable={false}
                    closeOnEscape={false}
                    closable={false}
                    blockScroll={true}
                    maskClassName={``}
                    style={{width: '37%'}}
                    data-testid='dropp-acct-dialog'                    
                >
                    <div className={`flex`}>
                        <AddWallet accessCode={cid} closeDialog={handleWalletDialogClose} />
                    </div>
                </Dialog>
            </main>
    )
};

const MaintWrapper = ({ children }) => {
    const [appState] = useAppStore();

    return (
      <MaintRoute appState = {appState}>
        {children}
      </MaintRoute>
    );
};

const ProtectedWrapper = ({ children }) => {
    const [sessionState] = useSessionStore();
    return (
      <ProtectedRoute sessionState={ sessionState }>
        {children}
      </ProtectedRoute>
    );
};

// Define the routes
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route 
            path="/" 
            element={<MaintWrapper><AppLayout /></MaintWrapper>}     //urlParams={ urlParams } 
        >
            <Route index element={<Default title="Default" />} />
            
            <Route path="home" element={<Default title="Default" />} />
            <Route path="metrics"element={<Metrics title="Metrics" />} />
            <Route path="contact"element={<Contact title="Contact" />} />
            <Route path="careers"element={<Careers title="Careers" />} />
            <Route path="terms" element={ <Terms />} />
            <Route path="privacy" element={ <Privacy />} />

            <Route path="dashboard" element={<Dashboard title="Dashboard" />} />
            <Route path="networkdata" element={<NetworkData title="Network Data" />} />
            <Route path="revenue" element={<Revenue title="Revenue" />} />
            <Route path="defionhedera" element={<Defi title="Defi On Hedera" />} />
            <Route path="stakingeconomics" element={<StakingEconomics title="Staking Economics" />} />
            <Route path="hbardistribution" element={<HbarDist title="HBAR Distribution" />} />
            <Route path="valuationmodel" element={<Analytics title={NAV_MODEL} />} />
            <Route path="insights" element={<Blog title="The Enlightened Hbarbarian" />} />
            <Route path="governingcouncil" element={<GC title={NAV_GOVCOUNCIL} />} />
            {/* <Route path="account" element={<Services title={NAV_ACCOUNT} />} /> */}

            {/* <Route element={
                <ProtectedWrapper>
                    <Outlet />
                </ProtectedWrapper>
                }>
                
            </Route> */}
            
            <Route path="roadmap" element={<Roadmap title={NAV_ROADMAP} />} />
            <Route path="notfound" element={<NotFound />} />
            <Route path="support" element={<Support title={NAV_SUPPORT} />} />
            <Route path="unsubscribe" element={ <Unsubscribe />} />
            {/* <Route path="unauthorized" element={ <Unauthorized />}/> */}
            <Route path="callback" element={ <Callback />} />
            <Route path="renew" element={ <Renew />} />
            <Route path="*" element={ <NotFound />} />
        </Route>
    ),
    {
        future: {
            v7_startTransition: true // Opt-in to the v7 behavior
        }
    }
);

const App = () => {
  return <ErrorBoundary>
            <ZoomProvider>
                <RouterProvider router={router} />
            </ZoomProvider>
        </ErrorBoundary>;
};

export default App;