    import * as cnst from './constants';

    export const RATE_DIV=100000000;
    export const APY_MULTI=100;
    export const MS_IN_HOUR=3600000;
    export const TBARS=100000000;
    export const NANO_MULTI=1000000;
    export const MS_DIV=1000000;
    export const NANO_DIV=1000000000
    export const SECONDS_IN_DAY=86400;
    export const NANO_IN_DAY=86400000000000;

    export const toHbar = (tinybars) => {
        const hbars = tinybars / TBARS;
        return hbars;
    }

export const formatHbar = (tinybars) => {
    var val = toHbar(tinybars);
    val = formatFloat(val);
    return formatHbarLabel(val);
}

export const formatHbarLabel = (val) => {
    return val + cnst.LBL_HBAR_SYMBOL;
}

export function formatInt(val) {
    return parseInt(val).toLocaleString();
}

export function formatToDecimal(val, precision) {
    if(val===null) return;
    var fixed = parseFloat(val.toFixed(precision))
    return fixed;
}

export function formatFloat(val) {
    const float = parseFloat(val).toLocaleString();
    return float;
}

export function formatMoney(val) {
    if(val===null) return;
    
    const retVal = val.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    return retVal;
}

export const formatPct = (val, precision) => {
    return val + '%';
}

//takes an array and returns the same array
export function formatData(data) {
    data.forEach(function(rec, idx){
        var objStr = '{';
        var ctr = 0;
        for (const [key, value] of Object.entries(rec)) {
            const formatted = formatDataValue(key, value);
            if(ctr > 0) {
                objStr += ', ';
            }
            objStr += '"' + key + '": "' + formatted + '"';
            ctr+=1;
        }

        objStr +=  '}';
        this[idx] = JSON.parse(objStr);

    }, data);

    return data;
}

function formatDataValue(key, value) {
    if(key.includes("timestamp")) {
        value = tsToDate(value);
    }
    if(key.includes("amount")) {
        value = formatHbar(value);
    }
    if (typeof value == "boolean") {
        value = value ? cnst.LBL_TRUE_VALUE : cnst.LBL_FALSE_VALUE;
    }
    
    value = (value===null) ? "" : value.toString();
    return value;
}

export function isAdmin(permissions) {
    return permissions==='9703094d-7ef3-4293-994b-4aff487b5add';
}

    export function emailRegex() {
        var pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
        return pattern;
    }
    
    export function getBreakpoints() {
        return { '960px': '75vw', '640px': '100vw' }
    }

    export function getSubDirectory() {
        const start = (window.location.toString().lastIndexOf('/')) + 1;
        const end = window.location.toString().length;
        const subdir = window.location.toString().substring(start, end).replace('#', '');
        return subdir;
    }

    export const round = (num, precision = 0) => {
        const factor = Math.pow(10, precision);
        return Number((Math.round((num + Number.EPSILON) * factor) / factor).toFixed(precision));
    };

export function calcPercent(a, b) {
    if(!a || !b) return;

    const num=a.toString().replaceAll(',','');
    const denom=b.toString().replaceAll(',','');

    return Math.round((num/denom + Number.EPSILON) * 100)?.toFixed(2);
}

export const getMetadataString = (hexx) => {
    let hex = hexx.toString() //force conversion
    hex = hex.split('\\x')[1]
    let str = ''
    for (let i = 0; i < hex.length; i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
    return str
}

export const groupArrayBy = function(arr, key) {
    return arr.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
    }, {});
};

export const groupArray = function(array, arrayProps) {
    //arrayProps = ['sector', 'geolocation']
    const grouped = Object.values(array.reduce((r, o) => {
        const key = arrayProps.map(k => o[k]).join('|');
        (r[key] ??= []).push(o);
        return r;
    }, {}));

    return grouped;
}

export const _groupArrBy = (array, f) => {
    var groups = {};
    array.forEach( function(o)
    {
        var group = JSON.stringify( f(o) );
        groups[group] = groups[group] || [];
        groups[group].push( o );  
    });

    return Object.keys(groups).map( function( group ) {
        return groups[group]; 
    })

}

/* --- Date functions ---*/

    export const getTimestamp = (dt) => {
        if(dt){
            return new Date(dt).getTime()*NANO_MULTI;
        } else
            return new Date().getTime()*NANO_MULTI;
    }

export function tsToDate(ts) {
    if(!ts) return;

    const dt = new Date(parseInt(ts.split('.')[0] + '000'));
    return formatDate(dt);
}

export const formatDate = (dt) => {
    let tempDt = new Date(dt);
    tempDt = new Date(tempDt.getTime() + Math.abs(tempDt.getTimezoneOffset()*60000))
    if(tempDt==="Invalid Date") return "n/a";

    var arr = tempDt.toString().split(' ');
    return `${arr[1]} ${arr[2]}, ${arr[3]}`;
}

    export const nanoToLocalDate = (nanoseconds) => {
        let temp = new Date(nanoseconds / NANO_MULTI)
        var tempDt = new Date(temp).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
        if(tempDt==="Invalid Date") return "n/a";

    //return tempDt;
    var arr = tempDt.split(',')
    return arr[1].replace(" ","") + ',' + arr[2];
}

    export const nowToNano = () => {
        return new Date().getTime() * NANO_MULTI;
    }

export const nowToSeconds = () => {
    return Math.round(new Date().getTime()/1000);
}

    export const utcDateToNano = (utcDate) => {
        return new Date(utcDate).getTime() * NANO_MULTI;
    }

    export const secondsToNano = (secs) => {
        return secs * NANO_MULTI;
    }

    export const nanoToSeconds = (nano) => {
        return nano/NANO_DIV;
    }

export const secondsToLocalDate = (secs) => {
    const nano = secondsToNano(secs);
    return nanoToLocalDate(nano);
}

export const secondsToUtcDate = (secs) => {
    const milliseconds = secs * 1000;
    const date = new Date(milliseconds);
    
    return date.toUTCString();
}

    export const nanoToUtcDate = (nano) => {
        const milliseconds = nano / MS_DIV;
        const date = new Date(milliseconds);
        
        return date.toUTCString();
    }

export function calendarDateToUtcDate(dt) {
    return Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate());
}

export function getDateParts(dt) {
    const arr = [];
        arr.push('day', dt.getDate());
        arr.push('month', dt.getMonth());
        arr.push('year', dt.getFullYear());
}

export function getHoursAgoTs(hrsAgo) {
    if(!hrsAgo) hrsAgo = 24;

    var ts = new Date(getUtcDate() - (hrsAgo * 60 * 60 * 1000));
    return ts.getTime();
}

export function getUTCEndOfDay() {
    var end = new Date();
    const eod = end.setUTCHours(23,59,59,999);
    return eod.toUTCString();
}

export function getUtcDateStr() {
    return new Date(new Date().toUTCString());
}

export function getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}

//leave ts param empty to drop timestamp
export function getUtcDate(ts) {
    const a = new Date();
    let utcDate;
    if(!ts)
        utcDate = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    else {
        var dt = getUtcCalendarDate();
        utcDate = Date.parse(dt);
    }

    return utcDate;
}

export function getUtcYear(dt) {
    const d = new Date(dt)
    return d.getUTCFullYear();
}

export function getUtcMonth(dt) {
    let month = new Date(dt).getUTCMonth()+1;
    return month;
}

export function getUtcDay(dt) {
    let day = new Date(dt).getUTCDate();
    return day;
}

export function daysDiff(startDt, endDt) {
    const oneDay = 1000 * 60 * 60 * 24;

    const start = Date.UTC(endDt.getFullYear(), endDt.getMonth(), endDt.getDate());
    const end = Date.UTC(startDt.getFullYear(), startDt.getMonth(), startDt.getDate());

    return (start - end) / oneDay;
}

export function getUtcCalendarDate() {
    return new Date().toISOString();
}

export function getUtcEpochDate() {
    var now = getUtcDateStr();
    return Math.floor(now/8.64e7);
}

export function getOperableEpoch() {
    var days = getUtcEpochDate();
    return days - 1;
}

export const getUtcDateMidnight = (year, month, day) => {
    if(!year) {
        year=getUtcYear(getUtcCalendarDate());
        month=getUtcMonth(getUtcCalendarDate());
        day=getUtcDay(getUtcCalendarDate());
    }
    const dt = new Date(`${year}/${month}/${day}`).setUTCHours(0,0,0,0);
    return dt;
}

export const daysToHours = (days) => {
    return days * 24;
}

export function secondsPerDay() {
    return 86400
}

    export const hoursToMs=(hours) => {
        return hours * MS_IN_HOUR;
    }

export const daysAgoToUtcDate = (daysAgo) => {
    var d = new Date(getUtcCalendarDate());
    var daysAgoDt = d.setDate(d.getDate() - daysAgo) - daysAgo;
    return daysAgoDt;
}

    export function mapFilterOption(period) {
        const now = new Date();
        const gmtNow = new Date(Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate()
        ));
        let targetDate;

        const utcHour = now.getUTCHours();
        const utcMinutes = now.getUTCMinutes();
        const isMidnightHour = utcHour === 0 && utcMinutes >= 0 && utcMinutes <= 59;

        switch (period) {
            case 'OA':
                return 1568592000000000000;
            case '7':
                // Set to midnight GMT of (current day - 6) to get exactly 7 days including today
                targetDate = new Date(Date.UTC(
                    gmtNow.getUTCFullYear(),
                    gmtNow.getUTCMonth(),
                    gmtNow.getUTCDate() - (isMidnightHour ? 7 : 6)
                ));
                break;
            case '30':
                // Set to midnight GMT of (current day - 29) to get exactly 30 days including today
                targetDate = new Date(Date.UTC(
                    gmtNow.getUTCFullYear(),
                    gmtNow.getUTCMonth(),
                    gmtNow.getUTCDate() - (isMidnightHour ? 30 : 29)
                ));
                break;
            case '90':
                // Set to midnight GMT of (current day - 89) to get exactly 90 days including today
                targetDate = new Date(Date.UTC(
                    gmtNow.getUTCFullYear(),
                    gmtNow.getUTCMonth(),
                    gmtNow.getUTCDate() - (isMidnightHour ? 90 : 89)
                ));
                break;

            case 'YTD':
                // January 1st of current year at midnight GMT
                targetDate = new Date(Date.UTC(
                    gmtNow.getUTCFullYear(),
                    0,
                    1
                ));
                break;
            case '1YEAR':
                // One year ago from current date at midnight GMT
                targetDate = new Date(Date.UTC(
                    gmtNow.getUTCFullYear(),
                    gmtNow.getUTCMonth(),
                    gmtNow.getUTCDate() - (isMidnightHour ? 366 : 365)
                ));
                break;
            default:
                targetDate = new Date(now.setDate(now.getDate() - 1));
        }
    
        return targetDate.getTime() * MS_DIV;
    }

export const msToNextHour = (interval) => {
    return interval - new Date().getTime() % interval;
}

    export const queryStaleTime = (interval) => {

        const staleTime = msToNextHour(interval) + 150000
        
        return staleTime;
    }

/* --- End date functions ---*/

    /* --- Start utility functions ---*/

    var cache = document.createElement("CACHE");
    document.body.appendChild(cache);
    export function preloadImages() {
        for (var i = 0; i < arguments.length; i++) {
            var img = new Image();
            img.src = arguments[i];
            var parent = arguments[i].split("/")[1]; // Set to index of folder name
            
            // Find or create parent div
            var parentDiv = document.querySelector(`cache #${parent}`);
            if (!parentDiv) {
                var ele = document.createElement("div");
                ele.id = parent;
                document.querySelector('cache').appendChild(ele);
            }
            
            // Append image to parent div
            document.querySelector(`cache #${parent}`).appendChild(img);
        }
    }

    

export const objectIsEmpty = (obj)=> {
    if(!obj) return true;
    return Object.keys(obj).length === 0;
}

export const padStringEnd = (str, len, char) => {
    return str.padEnd(len, "0");

}

export const calcTxns_ = (arr) => {
    return arr.map(function(v) { return v[1] })         // second value of each
        .reduce(function(a,b) { 
        return a + b;
    });      // sum
}

export const averageArray = array => array.reduce((a, b) => a + b) / array.length;

export function pxToVw(value) {
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth;

    var result = (100*value)/x;
    document.getElementById("result_px_vw").innerHTML = result;
    return result;
}

export function pxToVh(value) {
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

    var result = (100*value)/y;
    document.getElementById("result_px_vh").innerHTML = result;
    return result;
}

export function vwToPx(value) {
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth;

    var result = (x*value)/100;
    return result;
}

export function vhToPx(value) {
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

    var result = (y*value)/100;
    return result;
}

export function getElementsByClass(cls) {
    return document.getElementsByClassName(cls);
}
export function getElementById(id) {
    return document.getElementById(id);
}
export function getElementsByRole(role) {
    return document.querySelectorAll(`[role=${role}`)
}

export function toggleSidebar(cls) {
    var elems = getElementsByClass(cls);
    for(var i = 0; i < elems.length; i++){
        if (elems[i].style.display === "none") {
            elems[i].style.display = "inline-flex";
        } else {
            elems[i].style.display = "none";
        }
    }

    resizeCanvas();
}

export const resizeCanvas=()=> {
    var canvases = document.querySelectorAll('canvas');
    canvases.forEach(function(canvas) {
        canvas.style.width = '100%';
    });
}

export function addClass(elem, cls) {
    elem.classList.add(cls);
}

export function removeClass(elem, cls) {
    elem.classList.remove(cls);
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const emptyCache = () => {
    if('caches' in window){
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
    }
}

export function groupByKey(xs, key) {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function replaceAll(str, replaceChar, replaceWith) {
    return str.split(replaceChar).join(replaceWith);
}

export function arraysMatch(a, b) {
    return a.toString()===b.toString();
}

    export function randomInt(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    export function randomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

export function sortArray(arr, propIndex) {
    const comp = comparator(propIndex);
    return arr.sort(comp);
}

export function comparator(idx) {
    if(!idx) idx = 0;
    return (a, b) => {
        if (a[idx] < b[idx]) return -1;
        if (a[idx] > b[idx]) return 1;
        return 0;
    }
}

export const debounce = (func, timeout = 500) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, timeout);
    };
};

export const pixelsToRem = (px, base=16) => {
    const tempPx = `${px}`.replace('px', '')
  
    return (1 / base) * parseInt(tempPx)
}

export const chartProps = () => {        
    const vw = window.innerWidth;

    if(vw <=1024) {
        return ['7rem', vw/150, 1.5, vw/1750];
    }

    if(vw <=1100) {
        return ['8rem', vw/150, 3, vw/1750];
    }

    if(vw <=1280) {
        return ['9rem', vw/150, 3.5, vw/1500];
    }

    if(vw <=1530) {
        return ['12.5rem', vw/150, 4, vw/1500];
    }

    if(vw <=1745) {
        return ['13rem', vw/150, 4.5, vw/1000];
    }
    
    if(vw <=1920) {
        return ['14rem', vw/150, 5, vw/1500];
    }

    if(vw <=2048) {
        return ['14rem', vw/150, 5.5, vw/1500];
    }
    
    if(vw <=2400) {
        return ['16rem', vw/125, 6, vw/1500];
    }

    if(vw <=2560) {
        return ['20rem', vw/125, 6.5, vw/1250];
    }

    if(vw <=2880) {
        return ['24rem', vw/125, 7, vw/1250];
    }
    
    if(vw <=3850) {
        return ['28rem', vw/125, 9, vw/1350];
    }

    if(vw <=5800) {
        return ['40rem', vw/125, 14, vw/1500];
    }

    if(vw <=7652) {
        return ['50rem', vw/125, 18, vw/1500];
    }

    return ['55rem', vw/125, 24, vw/1500]
}

export const getBrowserIdentity = () => {
    try {            
        return navigator?.userAgent ?? "navigator cannot be determined";
    }
    catch(ex) {
        return "navigator error"
    }
    
}

export const findIndexById = (records, id, PK) => {
    let index = -1;
    for (let i = 0; i < records.length; i++) {
        if (records[i][PK]===id) {
            index = i;
            break;
        }
    }
    return index;
}


export const objectToQs = (obj, keys = []) =>
    Object.entries(obj).reduce((pairs, [key, value]) => {
        if(value===null) {
            pairs.push([[...keys, key.toLowerCase()], '']);
        }
        else {
            if (typeof value === 'object')
                pairs.push(...objectToQs(value ?? '', [...keys, key.toLowerCase()]));
            else
                pairs.push([[...keys, key.toLowerCase()], encodeURIComponent(value)]);
        }
    
    
        //.includes("world")
      return pairs;
    }, []);

/*--- End utility functions --*/


/*--- Start string functions --*/

export function right(str, chr) {
    const rightStr=str.substr(str.length-chr,str.length);
    return rightStr;
}

export function left(str, cnt) {
    return str.substr(0, cnt)
}

    export function capitalizeString(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    export const formatLargeNumber = (number) => {
        // Special case: if number is between 1000 and 999999, return as is
        if (number >= 1000 && number < 1000000) {
            return {
                number: number.toString(),
                suffix: ''
            };
        }
    
        // Define the suffixes and their corresponding divisors and decimal places
        const scales = [
            { value: 1e12, symbol: "T", decimals: 4 },
            { value: 1e9, symbol: "B", decimals: 3 },
            { value: 1e6, symbol: "M", decimals: 2 },
            { value: 1, symbol: "", decimals: 0 }
        ];
    
        // Find the appropriate scale
        const scale = scales.find(scale => Math.abs(number) >= scale.value);
        
        if (!scale) return { number: '0', suffix: '' };
    
        // Calculate the base number with the appropriate decimal places
        const baseNumber = (number / scale.value).toFixed(scale.decimals);
        
        // Only trim if all decimals are zeros
        const trimmedNumber = baseNumber.replace(/\.?0+$/, (match) => {
            // If the match starts with a decimal point and all following chars are '0',
            // remove it completely. Otherwise, keep the original number.
            return match === '.' + '0'.repeat(scale.decimals) ? '' : match;
        });
    
        return {
            number: trimmedNumber,
            suffix: scale.symbol
        };
    };
    
/*
    // Test cases
    const testCases = [
        1546000000000, // Should show 4 decimals for trillion (1.5460T)
        155630000000,   // Should show 3 decimals for billion (1.546B)
        1546000,       // Should show 2 decimals for million (1.55M)
        154600,        // Should return as is (154600)
        1546,          // Should return as is (1546)
        123,           // Should show no decimals (123)
        0,             // Should show no decimals (0)
        -1546000000,   // Should show 3 decimals for billion (-1.546B)
        1.23e12        // Should show 4 decimals for trillion (1.2300T)
    ];
    
    testCases.forEach(number => {
        const result = formatLargeNumber(number);
        console.log(`${number} → ${result.number}${result.suffix}`);
    });
*/

export function numberToWords(number) {
    if(number===0) return;
    if(number.length>=6) return number;

    const groups = [
        // { value: 1e18, symbol: 'quintillion' },
        // { value: 1e15, symbol: 'quadrillion' },
        { value: 1e12, symbol: 'T' },
        { value: 1e9, symbol: 'B' },
        { value: 1e6, symbol: 'M' },
        { value: 1e3, symbol: 'K' }
    ];

    for (let i = 0; i < groups.length; i++) {
        if (number >= groups[i].value) {
            return `$${(number / groups[i].value)?.toFixed(2).replace(/\.0$/, '') + '' + groups[i].symbol}`;
        }
    }
    return number.toString();
}

export function truncateString(str, len) {
    return str.substring(0, len - 1)
}

export function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const emptyGuid = () => {
    return "00000000-0000-0000-0000-000000000000";
}

export function padTrailingZeros(num, totalLength) {
    return String(num).padEnd(totalLength, '0');
}

function stringToHslColor(str, s, l) {
    var hash = 0;

    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    var h = hash % 360;
    return 'hsl('+h+','+s+'%,'+l+'%)';
}

export function getHslFromString(str) {
    var s = randomInt(50, 100);
    var l = randomInt(25, 75);
    var textColor = l > 65 ? '#555' : '#fff';
    var bgColor = stringToHslColor(str, s , l);

    return [bgColor, textColor].toString();
}

export const concatShardRealmAccountNum = (shard, realm, accountNum) => {
    return `${shard}.${realm}.${accountNum}`;
}

export function fieldNameToColumnName(field) {
    var arr = field.split("_");
    if(arr.length===1) {
        arr = field.split(".");
    }

    var colName="";
    arr.forEach((part, idx) => {
        part = part.replace("timestamp","date");

        //part = capitalizeFirstChar(part)
        if(idx > 0) {
            part = " " + part;
        }
        colName += part;        
    });

    return colName;
}

export function capitalizeFirstChar(word) {
    if(!word) return;
    
    const firstLetter = word.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = word.slice(1).toLowerCase();
    return firstLetterCap + remainingLetters;
}

export const timeframeToText = (tf) => {
    let text;

    switch(tf) {
        case '1':
            text = '24 Hours';
            break;
        case '7':
            text = '7 Days';
            break;
        case '30':
            text = '30 Days';
            break;
        case '365':
            text = '1 Year';
            break;
        default:
            text = tf
    }

    return text;
}

export function getDefictPrefix(val) {
    var prefix='';
    if(val < 0) {
        val=val.toString().replace("-","");
        prefix = '(';
    }

    return prefix;
}

export function getDefictSuffix(val) {
    var suffix='';
    if(val < 0) {
        suffix = ')'
    }

    return suffix;
}

/*--- End string functions --*/

    export function calcTps(blocks) {
        if(!blocks) return -1;
        
        const count = blocks?.[0]?.count ?? 0;
        const tsTo = blocks ? parseFloat(blocks?.[0]?.timestamp.to) : 0;
        const tsFrom = blocks ? parseFloat(blocks?.[0]?.timestamp.from) : 0;

    //console.log('blocks: ' + count + ', to: ' + tsTo + ', from: ' + tsFrom + ', diff: ' + (tsTo-tsFrom) + ', tps:' + Math.round(1 / (tsTo - tsFrom) * count) )

    if(tsTo-tsFrom < .1) {return -1}

    let tps = Math.round(1 / (tsTo - tsFrom) * count) !== undefined ? Math.round(1 / (tsTo - tsFrom) * count) : 0;
    
    //0.000001
    //blocks: 2, timestamp.to: 1730513692.876574, timestamp.to: 1730513692.876573 tps:2097152
    //blocks: 2, timestamp.to: 1730513656.9239962, timestamp.to: 1730513656.053543 tps:2

        if(tps===Infinity || tps===undefined || tps === null) {tps = 0};
        return tps;
    }
    
    export const appStoreMessage = (msg) => {
        return {message: msg}
    }

    export function isPromise(obj) {
        return obj instanceof Promise;
    }

    /**
     * Converts form data into a URL-encoded query string
     * @param {Object} formData - The form data object containing input values
     * @param {Array} checkboxArrays - Array of field names that should be treated as checkbox arrays
     * @returns {string} URL-encoded query string
     * 
     * Example usage:
     * const formData = {
     *   firstName: 'John',
     *   lastName: 'Doe',
     *   email: 'john@example.com',
     *   message: 'Hello\nWorld',
     *   cats: ['Engineering', 'Marketing'],
     *   ack: true
     * };
     * 
     * const queryString = serializeForm(formData, ['cats']);
     * // Result: firstName=John&lastName=Doe&email=john%40example.com&message=Hello%0AWorld&cats=Engineering&cats=Marketing&ack=true
     */
    
    export const serializeForm = (formData, checkboxArrays = []) => {
        // Convert the form data object into an array of key-value pairs
        let arrStr;
        const pairs = Object.entries(formData).reduce((acc, [key, value]) => {
            // Skip null or undefined values
            if (value == null) {
                return acc;
            }
            console.log('serializeForm formData', formData);

            // Handle arrays (like multiple checkboxes)
            if (checkboxArrays.includes(key) && Array.isArray(value)) {
                //Create a separate key-value pair for each array item
                const arrayPairs = value.map(item => [key, item]);
                return [...acc, ...arrayPairs];
            }
            if (checkboxArrays.length > 0) {
                arrStr = checkboxArrays.join(',')                
            }

            // Handle multiline text (textareas)
            if (typeof value === 'string' && value.includes('\n')) {
                return [...acc, [key, value]];
            }

            // Handle boolean values (single checkboxes)
            if (typeof value === 'boolean') {
                return [...acc, [key, value.toString()]];
            }

            // Handle all other cases
            return [...acc, [key, value]];
        }, []);

        // Convert the pairs array into a URL-encoded string
        let retStr = pairs
            .map(([key, value]) => {
                // Encode both key and value to handle special characters
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(value);
                return `${encodedKey}=${encodedValue}`;
            })
            .join('&');
            retStr += '&other=' + arrStr;
            return retStr;
    };

    export const post = async (service, endpoint, qs, url='') => {
        let route=`${endpoint}?${qs}&url=${url}`;

        try {
            const response = await service.post(route);
            
            if (response.success) {
                return {
                    success: true,
                    error: null,
                    data: response.data,
                    code: null,
                    details: 'Form has been successfully submitted'
                };
            }
            
            return {
                success: false,
                error: 'Form submission failed',
                data: null,
                code: response.status,
                details: response.error || null
            };
        } 
        catch (error) {
            return {
                success: false,
                error: 'An unexpected error occurred',
                data: null,
                code: error.response?.status,
                details: error.message
            };
        }
    };

    //export const get = async(service, endpoint) => {
    //}

    export function testMethods() {
        console.clear();
        console.log('getTimestamp(): ' + getTimestamp());
        console.log('getUtcDateMidnight(): ' + getUtcDateMidnight());
        console.log('getUtcDateMidnight(2023,1,1): ' + getUtcDateMidnight(2023,1,1));
        console.log('getUtcDateMidnight(2019,9,16): ' + getUtcDateMidnight(2019,9,16));
        console.log('formatDate("02/29/2024"): ' + formatDate('02/29/2024'));
        console.log('daysToHours(7): ' + daysToHours(7));
        console.log('daysAgoToUtcDate(7): ' + daysAgoToUtcDate(7));
        console.log('daysAgoToUtcDate(1): ' + daysAgoToUtcDate(1));
        console.log('daysAgoToUtcDate(365): ' + daysAgoToUtcDate(365));
        console.log('getUtcCalendarDate(): ' + getUtcCalendarDate());
        console.log('tsToDate("1694044799.000000"): ' + tsToDate('1694044799.000000'));
        console.log('nanoToLocalDate("1722122210178000000"): ' + nanoToLocalDate('1722122210178000000'));
        console.log('nanoToUtcDate("1722122210178000000"): ' + nanoToUtcDate('1722122210178000000'));
        console.log('getUtcDay(getUtcCalendarDate()): ' + getUtcDay(getUtcCalendarDate()));
        console.log('getUtcMonth(getUtcCalendarDate()): ' + getUtcMonth(getUtcCalendarDate()));
        console.log('getUtcYear(getUtcCalendarDate()): ' + getUtcYear(getUtcCalendarDate()));
        console.log('getUtcDate no ts: ' + getUtcDate());
        console.log('getUtcDate(1) with ts: ' + getUtcDate(1));
        console.log('getUtcDateStr: ' + getUtcDateStr());
        console.log('getUtcEpochDate: ' + getUtcEpochDate());
        console.log('getOperableEpoch: ' + getOperableEpoch());
        console.log('daysDiff(): ' + daysDiff(new Date(new Date().getFullYear(), 0, 1), new Date()))
        console.log('mapFilterOption: ' + mapFilterOption('7'));
        console.log('utcDateToNano("2024-08-27T20:36:18Z"): ' + utcDateToNano("2024-08-27T20:36:18Z"));
        console.log('randomInt: ' + randomInt(1, 1000));
        console.log('randomString: ' + randomString(10));
        //console.log('randomHslColor: ' + randomHslColor());
        //console.log('getHslFromString: ' + getHslFromString('Adam'));
    }

//testMethods();
