import React from 'react';
import './_boilerplate.scss';
import { APP_NAME } from './../../shared/constants';

const Terms = () => {
  return (
    <div className="terms mb-6">
      {/* Agreement Section */}
      <section className="terms__section">
        <div className="terms__section-title">Terms of Use</div>
        <div className="terms__content">
          <p>
            These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and { APP_NAME }, LLC ("Hashpoint", "Service", "Services"), concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </p>
          <p>
            Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about any changes by updating the "Last updated" date of these Legal Terms, and you waive any right to receive specific notice of each such change.
          </p>
          <p>
            The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services.
          </p>
        </div>
      </section>


      <div className="terms__content">
        {/* 1. Our Services */}
        <section className="terms__section">
          <div className="terms__section-title">1. Our Services</div>
          <div className="terms__content">
            <p>
              The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.
            </p>
            <p>
              The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
            </p>
          </div>
        </section>

        {/* 2. Intellectual Property Rights */}
        <section className="terms__section">
          <div className="terms__section-title">2. Intellectual Property Rights</div>
          <div className="terms__content">
            <h3 className="terms__subsection-title">Our intellectual property</h3>
            <p>
              We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").
            </p>
            <p>
              Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world.
            </p>
            <h3 className="terms__subsection-title">Your use of our Services</h3>
            <p>
              Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive, non-transferable, revocable license to access the Services and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use or internal business purpose.
            </p>
          </div>
        </section>

        {/* 3. User Representations */}
        <section className="terms__section">
          <div className="terms__section-title">3. User Representations</div>
          <div className="terms__content">
            <p>By using the Services, you represent and warrant that:</p>
            <ul>
              <li>You have the legal capacity and you agree to comply with these Legal Terms</li>
              <li>You are not a minor in the jurisdiction in which you reside</li>
              <li>You will not access the Services through automated or non-human means</li>
              <li>You will not use the Services for any illegal or unauthorized purpose</li>
              <li>Your use of the Services will not violate any applicable law or regulation</li>
            </ul>
          </div>
        </section>

        {/* 4. Purchases and Payment */}
        <section className="terms__section">
          <div className="terms__section-title">4. Purchases and Payment</div>
          <div className="terms__content">
            <p>All purchases are non-refundable.</p>
            <p>We accept various forms of payment. You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. Sales tax will be added to the price of purchases as deemed required by us. Payments shall be in US dollars.</p>
          </div>
        </section>

        {/* 5. Prohibited Activities */}
        <section className="terms__section">
          <div className="terms__section-title">5. Prohibited Activities</div>
          <div className="terms__content">
            <p>You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>
            <p>As a user of the Services, you agree not to:</p>
            <ul>
                <li>Download, copy, modify, distribue, transmit, or reproduce any content from any Service without prior written permission</li>
                <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory</li>
                <li>Trick, defraud, or mislead us and other users</li>
                <li>Circumvent, disable, or otherwise interfere with security-related features of the Services</li>
                <li>Make improper use of our support services</li>
                <li>Use the Services in a manner inconsistent with any applicable laws or regulations</li>
                <li>Engage in unauthorized framing of or linking to the Services</li>
                <li>Engage in data mining or data scraping</li>
            </ul>
          </div>
        </section>

        {/* 6. User Generated Contributions */}
        <section className="terms__section">
          <div className="terms__section-title">6. User Generated Contributions</div>
          <div className="terms__content">
            <p>
              The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or through the Services.
            </p>
            <p>Any Contributions you transmit may be treated as non-confidential and non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:</p>
            <ul>
              <li>Your Contributions are not false, inaccurate, or misleading</li>
              <li>Your Contributions do not violate any applicable law, regulation, or rule</li>
              <li>Your Contributions do not violate the privacy or publicity rights of any third party</li>
            </ul>
          </div>
        </section>

        {/* 7. Contribution License */}
        <section className="terms__section">
          <div className="terms__section-title">7. Contribution License</div>
          <div className="terms__content">
            <p>
              By posting your Contributions to any part of the Services, you automatically grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt, and distribute such Contributions.
            </p>
          </div>
        </section>

        {/* Additional sections... */}
        <section className="terms__section">
          <div className="terms__section-title">8. Guidelines for Reviews</div>
          <div className="terms__content">
            <p>We may provide you areas on the Services to leave reviews or ratings. When posting a review, you must comply with the following criteria:</p>
            <ul>
              <li>You should have firsthand experience with the person/entity being reviewed</li>
              <li>Your reviews should not contain offensive profanity, or abusive, racist, offensive, or hateful language</li>
              <li>Your reviews should not contain discriminatory references</li>
              <li>Your reviews should not contain false or misleading statements</li>
            </ul>
          </div>
        </section>

        {/* 9. Third-Party Websites */}
        <section className="terms__section">
          <div className="terms__section-title">9. Third-Party Websites and Content</div>
          <div className="terms__content">
            <p>
              The Services may contain links to other websites as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties. Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us.
            </p>
          </div>
        </section>

        {/* 10. Services Management */}
        <section className="terms__section">
          <div className="terms__section-title">10. Services Management</div>
          <div className="terms__content">
            <p>
              We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who violates the law or these Legal Terms; (3) refuse, restrict access to, limit the availability of, or disable any of your Contributions or any portion thereof; (4) remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
            </p>
          </div>
        </section>

        {/* Privacy Policy Section */}
        <section className="terms__section">
          <div className="terms__section-title">11. Privacy Policy</div>
          <div className="terms__content">
            <p>
              We care about data privacy and security. Please review our Privacy Policy at: www.hashpoint.io/privacy. By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms.
            </p>
          </div>
        </section>

        {/* Term and Termination */}
        <section className="terms__section">
          <div className="terms__section-title">12. Term and Termination</div>
          <div className="terms__content">
            <p>
              These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES TO ANY PERSON FOR ANY REASON OR FOR NO REASON.
            </p>
          </div>
        </section>

        {/* Modifications and Interruptions */}
        <section className="terms__section">
          <div className="terms__section-title">13. Modifications and Interruptions</div>
          <div className="terms__content">
            <p>
              We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
            </p>
          </div>
        </section>

        {/* Governing Law */}
        <section className="terms__section">
          <div className="terms__section-title">14. Governing Law</div>
          <div className="terms__content">
            <p>
              These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of the State of Nevada applicable to agreements made and to be entirely performed within the State of Nevada, without regard to its conflict of law principles.
            </p>
          </div>
        </section>

        {/* Dispute Resolution */}
        <section className="terms__section">
          <div className="terms__section-title">15. Dispute Resolution</div>
          <div className="terms__content">
            <p>
              Any legal action of whatever nature brought by either you or us shall be commenced or prosecuted in the state and federal courts located in Nevada, and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in such state and federal courts.
            </p>
          </div>
        </section>

        {/* Corrections */}
        <section className="terms__section">
          <h2 className="terms__section-title">16. Corrections</h2>
          <div className="terms__content">
            <p>
              There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.
            </p>
          </div>
        </section>

        {/* Disclaimer */}
        <section className="terms__section">
          <h2 className="terms__section-title">17. Disclaimer</h2>
          <div className="terms__content">
            <p>
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
            </p>
          </div>
        </section>

        {/* Limitations of Liability */}
        <section className="terms__section">
          <h2 className="terms__section-title">18.  Limitations of Liability</h2>
          <div className="terms__content">
            <p>
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES.
            </p>
          </div>
        </section>

        {/* Indemnification */}
        <section className="terms__section">
          <h2 className="terms__section-title">19. Indemnification</h2>
          <div className="terms__content">
            <p>
              You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys' fees and expenses, made by any third party due to or arising out of your use of the Services.
            </p>
          </div>
        </section>

        {/* User Data */}
        <section className="terms__section">
          <h2 className="terms__section-title">20.User Data </h2>
          <div className="terms__content">
            <p>
              We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services.
            </p>
          </div>
        </section>

        {/* Electronic Communications */}
        <section className="terms__section">
          <h2 className="terms__section-title">21. Electronic Communications</h2>
          <div className="terms__content">
            <p>
              Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically satisfy any legal requirement that such communication be in writing.
            </p>
          </div>
        </section>

        {/* SMS Text Messaging */}
        <section className="terms__section">
          <h2 className="terms__section-title">22. SMS Text Messaging</h2>
          <div className="terms__content">
            <h3 className="terms__subsection-title">Opting Out</h3>
            <p>
              If at any time you wish to stop receiving SMS messages from us, simply reply to the text with "STOP." You may receive an SMS message confirming your opt out.
            </p>
            <h3 className="terms__subsection-title">Message and Data Rates</h3>
            <p>
              Please be aware that message and data rates may apply to any SMS messages sent or received. The rates are determined by your carrier and the specifics of your mobile plan.
            </p>
            <h3 className="terms__subsection-title">Support</h3>
            <p>
              If you have any questions or need assistance regarding our SMS communications, please email us at support@hashpoint.io.
            </p>
          </div>
        </section>

        {/* California Users */}
        <section className="terms__section">
          <h2 className="terms__section-title">23. California Users and Residents</h2>
          <div className="terms__content">
            <p>
                If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
            </p>
          </div>
        </section>

        <section className="terms__section">
          <h2 className="terms__section-title">24. Termination</h2>
          <div className="terms__content">
            <p>
                We reserve the right to deny access to any Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.
            </p>
          </div>
        </section>

        <section className="terms__section">
          <h2 className="terms__section-title">25. Severability</h2>
          <div className="terms__content">
            <p>
                If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced.
            </p>
          </div>
        </section>


        {/* Contact Information */}
        <section className="terms__section terms__text-center">
          <div className="terms__section-title">Contact Information</div>
          <div className="terms__content">
            <p>If you have any questions about these Terms of Use, please contact us at:</p>
            <p className="terms__font-medium">support@hashpoint.io</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Terms;