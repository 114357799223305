// apiService.js
import axios from 'axios';

class ApiService {
    constructor() {
        // Base configuration
        this.baseURL = "callback";
        this.defaultHeaders = {
            'Content-Type': 'application/json'
        };
    }

    /**
     * Generic API call method
     * @param {Object} config - The request configuration
     * @param {string} config.functionName - AWS Lambda function name
     * @param {string} config.method - HTTP method (GET, POST, PUT, DELETE)
     * @param {Object} [config.params] - URL parameters
     * @param {Object} [config.data] - Request body data
     * @param {Object} [config.headers] - Additional headers
     * @param {boolean} [config.requiresAuth=true] - Whether the request requires authentication
     * @returns {Promise} API response
     */
    async call({
        functionName,
        method = 'GET',
        params = {},
        data = null,
        headers = {},
        requiresAuth = false
    }) {
        try {
            // Construct request URL
            const url = `${this.baseURL}/${functionName}`;

            // Get auth token if required
            let authHeaders = {};
            if (requiresAuth) {
                const token = await this.getAuthToken();
                authHeaders = {
                    'Authorization': `Bearer ${token}`
                };
            }

            // Merge all headers
            const requestHeaders = {
                ...this.defaultHeaders,
                ...authHeaders,
                ...headers
            };

            // Make API request
            const response = await axios({
                url,
                method: method.toUpperCase(),
                params,
                data,
                headers: requestHeaders,
                validateStatus: status => status < 500 // Don't reject if status < 500
            });

            // Handle response
            if (response.status >= 200 && response.status < 300) {
                return {
                    success: true,
                    data: response.data,
                    status: response.status
                };
            } 
            else {
                throw new Error(response.data?.message || 'Request failed');
            }

        } 
        catch (error) {
            // Handle errors
            console.error(`API Error (${functionName}):`, error);
            return {
                success: false,
                error: error.message,
                status: error.response?.status,
                data: error.response?.data
            };
        }
    }

    /**
     * Get authentication token - Implement based on your auth system
     * @returns {Promise<string>} Authentication token
     */
    async getAuthToken() {
        // Implement your token retrieval logic here
        // Example: return localStorage.getItem('authToken');
        // Or: return Auth.currentSession().then(session => session.getIdToken().getJwtToken());
    }

    /**
     * Helper method for GET requests
     * @param {string} functionName - AWS Lambda function name
     * @param {Object} [params] - URL parameters
     * @param {Object} [config] - Additional configuration
     */
    async get(functionName, params = {}, config = {}) {
        return this.call({
            functionName,
            method: 'GET',
            params,
            ...config
        });
    }

    /**
     * Helper method for POST requests
     * @param {string} functionName - AWS Lambda function name
     * @param {Object} data - Request body
     * @param {Object} [config] - Additional configuration
     */
    async post(functionName, data = {}, config = {}) {
        return this.call({
            functionName,
            method: 'POST',
            data,
            ...config
        });
    }

    /**
     * Helper method for PUT requests
     * @param {string} functionName - AWS Lambda function name
     * @param {Object} data - Request body
     * @param {Object} [config] - Additional configuration
     */
    async put(functionName, data = {}, config = {}) {
        return this.call({
            functionName,
            method: 'PUT',
            data,
            ...config
        });
    }

    /**
     * Helper method for DELETE requests
     * @param {string} functionName - AWS Lambda function name
     * @param {Object} [params] - URL parameters
     * @param {Object} [config] - Additional configuration
     */
    async delete(functionName, params = {}, config = {}) {
        return this.call({
            functionName,
            method: 'DELETE',
            params,
            ...config
        });
    }
}

const apiService = new ApiService();
export default apiService;