import React from 'react';
import ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';
import App from './App';

import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const originalConsoleWarn = console.warn;
console.warn = function (...args) {
    if (args[0] && args[0].includes('React Router Future Flag Warning')) {
        return; // Ignore the warning
    }
    originalConsoleWarn.apply(console, args);
};


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

  const Entrypoint = () => {
    return (
        root.render(            
            <React.StrictMode>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <App />
                </QueryClientProvider>
            </React.StrictMode>            
        )
    )
  }

  Entrypoint();

//if ('serviceWorker' in navigator) {
    //// Register a service worker hosted at the root of the
    // site using the default scope.
    //navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then(
    //  registration => {
    //    console.log('Service worker registration succeeded:', registration);
    //  },
    //  /*catch*/ error => {
    //    console.error(`Service worker registration failed: ${error}`);
    //  }
    //);
//} else {
//    console.error('Service workers are not supported.');
//}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
