import React, { useState, useCallback, createElement } from 'react';
import { Link } from "react-router-dom";
import { Menu } from 'primereact/menu';
import { 
    CircleGauge
    , LayoutDashboard
    , Waypoints
    , ChartCandlestick
    , ChartSpline
    , Radical
    , ChartPie
    , ChartScatter
    , BookOpenText
    , Earth
    , User
    , ChevronsLeft
    , ChevronsRight
} from 'lucide-react';
import { getSubDirectory, toggleSidebar, getElementsByClass, removeClass, addClass, replaceAll, delay } from '../shared/fn';
import { 
    NAV_DASHBOARD
    , NAV_OPS_DATA
    , NAV_REVENUE
    , NAV_STAKING_ECONOMICS
    , NAV_DEFI_ON_HEDERA
    , NAV_HBARDIST
    , NAV_MODEL
    , NAV_BLOG
    , NAV_GOVCOUNCIL
    , NAV_ACCOUNT
    , NAV_NETWORK_METRICS
} from '../shared/constants';
import './_menu.scss';

const NavMenu = ({maintMode}) => {
    const subdir = getSubDirectory();
    const [activeMenuIndex, setActiveMenuIndex] = useState(-1);
    const [collapsed, setCollapsed] = useState(false);

    const toggleSidenav = useCallback(() => {
        setCollapsed(!collapsed);
        toggleSidebar('nav-text');
        const sidebar = getElementsByClass("sidebar")[0];
        
        localStorage.setItem('__sak:TzJtn1OaqW', !collapsed);

        if(collapsed) {
            removeClass(sidebar, 'collapsed');
            addClass(sidebar, 'expanded');
        } 
        else {
            removeClass(sidebar, 'expanded');
            addClass(sidebar, 'collapsed');
        }
    }, [collapsed]);
    
    const handleMenuClick = async(idx) => {
        setActiveMenuIndex(idx);
        await delay(250);
        sessionStorage.setItem('__sak:path', subdir);
    }

    const createMenuItem = (label, icon, index, override) => ({
        label,
        template: () => (
            <div className="nav-item flex align-items-center cursor-pointer">
                <Link 
                    to={replaceAll(override ? override.toLowerCase() : label.toLowerCase(), ' ', '')} 
                    onClick={() => handleMenuClick(index)}
                    className={`nav-item-link`}
                    title={`${collapsed ? label : ''}`}
                >
                    {createElement(icon, {
                        className: `lucide-icon menu-item ${activeMenuIndex === index || subdir === replaceAll(override ? override.toLowerCase() : label.toLowerCase(), ' ', '') ? 'selected' : ''}`
                    })}
                    <span className={`nav-text ${activeMenuIndex === index || subdir === replaceAll(override ? override.toLowerCase() : label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                        {label}
                    </span>
                </Link>
            </div>
        )
    });

    const items = [
        {
            label: '',
            template: () => <div className="menu-spacer">&nbsp;</div>
        },
        createMenuItem(NAV_NETWORK_METRICS, LayoutDashboard, 0, 'metrics'),
        createMenuItem(NAV_DASHBOARD, CircleGauge, 1),
        createMenuItem(NAV_OPS_DATA, Waypoints, 2, 'networkdata'),
        createMenuItem(NAV_REVENUE, ChartCandlestick, 3),
        createMenuItem(NAV_STAKING_ECONOMICS, ChartPie, 4),
        createMenuItem(NAV_DEFI_ON_HEDERA, ChartSpline, 5),
        createMenuItem(NAV_HBARDIST, ChartScatter, 6),
        createMenuItem(NAV_MODEL, Radical, 7),
        createMenuItem(NAV_BLOG, BookOpenText, 8),
        createMenuItem(NAV_GOVCOUNCIL, Earth, 9),
        //createMenuItem(NAV_ACCOUNT, User, 10),
        {
            label: '',
            template: () => (
                <div className="toggle">
                    <ChevronsLeft 
                        className={collapsed ? 'hidden' : 'expanded no-bg no-border hand menu-item'} 
                        onClick={toggleSidenav} 
                    />
                    <ChevronsRight 
                        className={collapsed ? 'collapsed no-bg no-border hand menu-item' : 'hidden'} 
                        onClick={toggleSidenav}
                    />
                </div>
            )
        }
    ];
    
    return (
        <div className={`${maintMode ? 'hidden' : ''}`}>
            <Menu className='nav-menu' model={items} multiple />
        </div> 
    );
}

export default NavMenu;