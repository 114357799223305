import React from 'react';
import { Dialog } from 'primereact/dialog';

const errorContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem'
};

const errorMessageStyle = {
  color: '#dc2626',
  fontWeight: 600
};

const detailsStyle = {
  whiteSpace: 'pre-wrap'
};

const summaryStyle = {
  cursor: 'pointer',
  marginBottom: '0.5rem'
};

const detailsContentStyle = {
  padding: '1rem',
  borderRadius: '4px',
  backgroundColor: '#f8f9fa'
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    this.setState({
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Dialog 
          header="Something went wrong"
          visible={true}
          style={{ 
                width: '20rem',
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                margin: 0
          }}
          modal={false}
          closable={true}
          onHide={() => this.setState({ hasError: false })}
          draggable={false}
          resizable={false}
        >
          <div style={errorContainerStyle}>
            <p style={errorMessageStyle}>
              An error occurred in the application
            </p>
            <details style={detailsStyle}>
              <summary style={summaryStyle}>Error Details</summary>
              <div style={detailsContentStyle}>
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </div>
            </details>
          </div>
        </Dialog>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;